<template>
    <div class="mining">
        <NavBar />
        <div class="en" v-if="lang == 'en'">
            <div class="mining-img">
                <img src="../assets/mining/mining asset.png" alt="">
            </div>
            <div class="hero-img">
                <!-- <img src="../assets/mining/cxt hero.png" alt=""> -->
                <div class="mining-title" style="font-size:60px;color:orange;">CXT is here</div>
            </div>
            <div class="mining-title">Join the thousands of CXT miners now</div>
            <div class="mining-text">
                <div class="list-title">1. Download Java8:</div>
                <div class="tab-1"><a href="https://www.java.com/en/download/manual.jsp" target="_blank">https://www.java.com/en/download/manual.jsp</a></div>
                <div class="list-title">2. Download CXT .jar file</div>
                <div class="tab-1">
                    <button class="mining-btn" @click="downloadFile()">Download</button>
                </div>
                <div class="list-title">3. CXT Wallet:</div>
                <div class="tab-1"><a href="https://cxt.coexstar.com/#/" target="_blank">https://cxt.coexstar.com/#/</a></div>
                <div class="list-title">4. Initiating CXT Mining</div>
                <div class="tab-1">a. save the jar file</div>
                <div class="tab-1">b. register to cxt.coexstar.com to get your wallet address</div>
                <div class="tab-1">c. locate your jar file and execute this command using your terminal:</div>
                <div class="tab-2">java -jar cxt-mining-1.0.0-mainnet.jar --wallet.address=&lt;wallet address&lt;</div>
                <div class="list-title">5. CXT Explorer</div>
                <div class="tab-1"><a href="https://explorer-cxt.coexstar.com/#/" target="_blank">https://explorer-cxt.coexstar.com/#/</a></div>
            </div>
        </div>
        <div class="kr" v-if="lang == 'kr'">
            <div class="mining-img">
                <img src="../assets/mining/mining asset.png" alt="">
            </div>
            <div class="hero-img">
                <!-- <img src="../kr-assets/mining/cxt hero.png" alt=""> -->
                <div class="mining-title" style="font-size:60px;color:orange;">CXT 가 왔습니다</div>
            </div>
            <div class="mining-title">지금 수천 명의 CXT 채굴자와 함께하세요</div>
            <div class="mining-text">
                <div class="list-title">1. . Java8 다운로드:</div>
                <div class="tab-1"><a href="https://www.java.com/en/download/manual.jsp" target="_blank">https://www.java.com/en/download/manual.jsp</a></div>
                <div class="list-title">2. CXT .jar 파일 다운로드</div>
                <div class="tab-1">
                    <button class="mining-btn">다운로드</button>
                </div>
                <div class="list-title"> CXT 지갑:</div>
                <div class="tab-1"><a href="https://cxt.coexstar.com/#/" target="_blank">https://cxt.coexstar.com/#/</a></div>
                <div class="list-title">4. CXT 채굴 시작:</div>
                <div class="tab-1">a. jar 파일 저장</div>
                <div class="tab-1">b. 지갑 주소를 얻기 위해  cxt.coexstar.com에 등록</div>
                <div class="tab-1">> c. jar 파일을 찾고 터미널을 사용하여 이 명령을 실행합니다 : </div>
                <div class="tab-2">java -jar cxt-mining-1.0.0-mainnet.jar --wallet.address=&lt;wallet address&lt;</div>
                <div class="list-title">5. CXT Explorer</div>
                <div class="tab-1"><a href="https://explorer-cxt.coexstar.com/#/" target="_blank">https://explorer-cxt.coexstar.com/#/</a></div>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import FooterComponent from '../components/FooterComponent.vue'
export default {
    components: {
        NavBar,
        FooterComponent
    },

    data() {
        return {
            lang: 'en'
        }
    },

    methods: {
        goTo(route) {
            this.$router.push({name: route, params: {lang:  localStorage.getItem('lang')}})
        },

        setLang() {
            this.lang = localStorage.getItem('lang')
        },

        downloadFile() {
            // Replace 'your_file_url' with the actual URL of your file
            const fileUrl = 'http://cxt.digital/cxt-mining-1.0.0-mainnet.jar';
            
            // Create a temporary <a> element
            const link = document.createElement('a');
            link.href = fileUrl;
            
            // Set the download attribute to the desired file name
            link.setAttribute('download', 'cxt-mining-1.0.0-mainnet.jar');
            
            // Append the link to the body
            document.body.appendChild(link);
            
            // Programatically trigger a click event to start the download
            link.click();
            
            // Clean up by removing the link from the DOM
            document.body.removeChild(link);
        }
    },

    mounted() {
        this.setLang()
    }
}
</script>

<style scoped>
    .mining {
        background-image: url(../assets/mining/mining-bg.png);
        background-size:cover;
        background-repeat:no-repeat;
        background-position: center;
        min-height:100vh;
    }

    .mining-img img {
        width:100%;
        object-fit:contain;
    }

    .hero-img {
        text-align:center;
        margin-bottom:10px;
    }

    .mining-title {
        text-align:center;
        font-weight:bold;
        font-size:30px;
    }

    .mining-text {
        width:70%;
        margin:0 auto;
    }

    .mining-text {
        font-size:20px;
        padding:40px;
    }
    
    .list-title {
        font-weight:bold;
        margin-bottom:20px;
    }

    .tab-1 {
        margin-left:20px;
        margin-bottom:10px;
    }

    .tab-2 {
        margin-left:40px;
        margin-bottom:10px;
    }

    .mining-btn {
        background:none;
        border:1px red solid;
        border-radius:20px;
        padding:10px;
        width:150px;
        margin-bottom:10px;
    }

    @media screen and (max-width:800px) {
        .hero-img {
            text-align:center;
        }

        .hero-img img {
            width:70%;
        }

        .mining-title {
            width:70%;
            margin:0 auto;
        }

        .mining-text {
            font-size:15px;
        }
    }
</style>