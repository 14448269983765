<template>
    <div class="home">
        <NavBar />
        <div class="en" v-if="lang == 'en'">
            <div class="hero">
                <div class="hero-img">
                    <img src="../assets/Home/hero.png" alt="">
                </div>
                <div class="hero-text">
                    CXT is changing the industry for the better.
                </div>
                <!-- <div class="info-row">
                    <div class="info-card">
                        <div class="card-title">TXN per second</div>
                        <div class="card-text">0.01234</div>
                    </div>
                    <div class="info-card">
                        <div class="card-title">TXN per second</div>
                        <div class="card-text">0.01234</div>
                    </div>
                    <div class="info-card">
                        <div class="card-title">TXN per second</div>
                        <div class="card-text">0.01234</div>
                    </div>
                    <div class="info-card">
                        <div class="card-title">TXN per second</div>
                        <div class="card-text">0.01234</div>
                    </div>
                    <div class="info-card">
                        <div class="card-title">TXN per second</div>
                        <div class="card-text">0.01234</div>
                    </div>
                </div> -->
            </div>
            <div class="about-cxt">
                <div class="about-img">
                    <img src="../assets/Home/aset1.png" alt="">
                </div>
                <div class="about-text">
                    <p>
                        CXT is a decentralized peer-to-peer open source 
                        cryptocurrency created by CXT,  a Decentralized 
                        Autonomous Organization (DAO), with the goal 
                        of being the most accessible and easiest to use 
                        amongst all the cryptocurrencies in the world.
                    </p>
                    <p>
                        CXT is a decentralized peer-to-peer open source 
                        cryptocurrency created by CXT,  a Decentralized 
                        Autonomous Organization (DAO), with the goal 
                        of being the most accessible and easiest to use 
                        amongst all the cryptocurrencies in the world.
                    </p>
                    <a href="/#/en/about"><button class="home-btn">ABOUT</button></a>
                </div>
            </div>
            <div class="features">
                <div class="features-text">
                    CXT CAN BE MINED BY ANYONE IN MINUTES, USING COST EFFICIENT AND SUSTAINABLE
                    POWER SOLUTIONS. FAIR DISTRIBUTION. TRUE INCLUSION. A UNIVERSAL MISSION.
                </div>
                <div class="features-img">
                    <img src="../assets/Home/asset 3.png" alt="">
                </div>
                <div class="features-text-2">
                    CXT is accessible, simple to use and most of all, FREE. Windows, Mac and Linux
                    compatible, without the burden of extra costs to keep your mining equipment cool.
                </div>
            </div>
            <div class="mining">
                <div class="mining-img">
                    <img src="../assets/Home/asset2.png" alt="">
                </div>
                <div class="mining-content">
                    <div class="mining-title">How to mine CXT</div>
                    <div class="mining-text">With CXT, anyone can be a cryptocurrency miner.</div>
                    <div class="mining-text">
                        A CXT Coin can be mined by anyone—even the average
                        home miner— in minutes, using cheap and sustainable
                        power solutions. CXT doesn’t require expensive hardware
                        and enormous computing power to be mined,
                        presenting a profitable venture especially for home miners.
                    </div>
                    <div class="mining-text">
                        CXT and its entire supply can only be acquired through
                        Smart Mining via laptops and desktops.
                    </div>
                    <a href="/#/en/mining"><button class="home-btn">Mining</button></a>
                </div>
            </div>
            <div class="contract">
                <div class="contract-content">
                    <div class="contract-title">
                        Efficiency. Accuracy.
                        Reliability. Experience the
                        Advantages of Smart Contracts
                    </div>
                    <div class="contract-text">
                        Simplify Complex Agreements and Ensure Flawless Execution 
                    </div>
                    <a href="/#/en/contract"><button class="home-btn">Smart Contract</button></a>
                </div>
                <div class="contract-img">
                    <img src="../assets/Home/asset3.png" alt="">
                </div>
            </div>
            <div class="explorer">
                <div class="explorer-title">
                    CXT Blockchain Explorer:
                    See the Future of Transparency
                </div>
                <div class="explorer-text">
                    Uncover the inner workings and transactions of the CXT Blockchain with our intuitive
                    and user-friendly explorer. Gain unparalleled visibility into the transparent ecosystem of
                    CXT and explore the power of immutable records.
                </div>
                <a href="https://explorer-cxt.coexstar.com/#/"><button class="home-btn">Explorer</button></a>
            </div>
        </div>
        <!-- KOREAN -->
        <div class="kr" v-if="lang == 'kr'">
            <div class="hero">
                <div class="hero-img">
                    <img src="../kr-assets/Home/hero.png" alt="">
                </div>
                <div class="hero-text">
                    CXT는 업계를 더 나은 방향으로 변화시키고 있습니다.
                </div>
                <!-- <div class="info-row">
                    <div class="info-card"></div>
                    <div class="info-card"></div>
                    <div class="info-card"></div>
                    <div class="info-card"></div>
                    <div class="info-card"></div>
                </div> -->
            </div>
            <div class="about-cxt">
                <div class="about-img">
                    <img src="../assets/Home/aset1.png" alt="">
                </div>
                <div class="about-text">
                    <p>
                        CXT는 분산형 자율 조직(DAO)인 CXT가 만든 분산형 P2P
                        오픈 소스 암호화폐로, 전 세계 모든 암호화폐 중에서 가장
                        접근하기 쉽고 사용하기 쉬운 것을 목표로 합니다.
                    </p>
                    <p>
                        CXT는 지난 2020년 11월 8일 총 공급량 84,000,000개로
                        채굴 및 배포를 위한 첫 번째 노드를 출시했습니다. CXT는
                        어디서나 누구나 참여할 수 있는 친환경 채굴 플랫폼을
                        구현하여 암호화폐 접근성을 혁신하는 것을 목표로 합니다.
                    </p>
                    <a href="/#/kr/about"><button class="home-btn">CXT추가정보</button></a>
                </div>
            </div>
            <div class="features">
                <div class="features-text">
                    CXT는 비용 효율적이고 지속 가능한 방법을 사용하여 누구나 몇 분 안에 채굴할 수 있습니다.
                    파워 솔루션. 공정한 배포. 진정한 포용. 보편적인 임무.
                </div>
                <div class="features-img">
                    <img src="../kr-assets/Home/asset 3.png" alt="">
                </div>
                <div class="features-text-2">
                    cxt 는 접근이 편리하고 무료입니다. Windows, Mac, linux 와 호환이 되며 채굴 장비 온도를 낮추기 위한 추가 비용이 들지 않습니다.
                </div>
            </div>
            <div class="mining">
                <div class="mining-img">
                    <img src="../kr-assets/Home/asset2.png" alt="">
                </div>
                <div class="mining-content">
                    <div class="mining-title">CXT를 채굴하는 방법</div>
                    <div class="mining-text">CXT를 사용하면 누구나 암호화폐 채굴자가 될 수 있습니다.</div>
                    <div class="mining-text">
                        CXT 코인은 저렴하고 지속 가능한 전력 솔루션을 사용하여 일반인
                        누구나 채굴자가 될 수 있고 몇분안에 채굴이 가능합니다. CXT는
                        채굴을 위해 비싼 성능의 컴퓨터를 필요하지 않으므로 집에서
                        채굴하는 것도 수익성을 제공합니다.
                    </div>
                    <div class="mining-text">
                        CXT와 전체 공급량은 노트북과 데스크탑을 통한 스마트 마이닝을
                        통해서만 얻을 수 있습니다.
                    </div>
                    <a href="/#/kr/mining"><button class="home-btn">채굴</button></a>
                </div>
            </div>
            <div class="contract">
                <div class="contract-content">
                    <div class="contract-title">
                        효율성. 정확성. 신뢰성. 스마트 계약의 장점들을 경험해보세요.
                    </div>
                    <div class="contract-text">
                        복잡한 계약을 단순화하고 완벽한 실행을 보장합니다.
                    </div>
                    <a href="/#/kr/contract"><button class="home-btn">스마트 계약</button></a>
                </div>
                <div class="contract-img">
                    <img src="../assets/Home/asset3.png" alt="">
                </div>
            </div>
            <div class="explorer">
                <div class="explorer-title">
                    cxt 블록체인 탐색기: 투명성의 미래를 경험해보세요
                </div>
                <div class="explorer-text">
                    직관적이고 사용자 친화적인 탐색기로 CXT 블록체인의 내부 작동과 거래를 확인하세요. 투명성으로 CXT의 투명한 생태계를 보고 불변의 기록의 힘을 경험하세요.
                </div>
                <a href="https://explorer-cxt.coexstar.com/#/"><button class="home-btn">탐색기</button></a>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import FooterComponent from '../components/FooterComponent.vue'
export default {
    components: {
        NavBar,
        FooterComponent
    },

    data() {
        return {
            lang: 'en'
        }
    },

    methods: {
        setLang() {
            this.lang = localStorage.getItem('lang')
        }
    },

    mounted() {
        this.setLang()
    }
}
</script>

<style scoped>
    .home {
        background-image: url(../assets/Home/home\ bg-08.png);
        background-size:cover;
        background-repeat:no-repeat;
        background-position: center;
    }

    .hero {
        text-align:center;
        padding:30px;
    }

    .hero-img {
        margin-bottom:20px;
    }

    .hero-img img {
        width:60%;
        object-fit:contain;
    }

    .hero-text {
        font-size:30px;
    }

    .about-cxt {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-around;
        padding:30px;
    }

    .about-img img {
        width:600px;
        object-fit: contain;
        margin:auto 0;
    }

    .about-text {
        width:500px;
        font-size:18px;
        margin:auto 0;
    }

    .home-btn {
        background:none;
        border:1px red solid;
        border-radius:20px;
        padding:10px;
        width:150px;
        margin-top:20px;
    }

    .features {
        text-align:center;
        padding:30px;
    }
    
    .features-text {
        font-weight:bold;
        width:70%;
        margin:20px auto;
        font-size:20px;
    }

    .features-text-2 {
        width:70%;
        margin:20px auto;
        font-size:20px;
    }

    .mining {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-around;
    }

    .mining img {
        width:600px;
        object-fit:contain;
    }

    .mining-content {
        width:500px;
        margin:auto 0;
    }

    .mining-title {
        font-size:40px;
        font-weight:bold;
        margin-bottom:30px;
    }

    .mining-text {
        margin-bottom:15px;
    }

    .contract {
        display:flex;
        justify-content:center;
        flex-wrap:wrap;
        padding:30px;
    }
    
    .contract-content {
        width:600px;
        margin:auto 0;
    }

    .contract-title {
        font-weight:bold;
        font-size:40px;
        margin-bottom:15px;
    }

    .contract-text {
        font-size:20px;
    }

    .contract-img img {
        width:600px;
        object-fit:contain;
    }

    .explorer {
        padding:30px;
        text-align:center;
    }

    .explorer-title {
        width:60%;
        margin:30px auto;
        font-weight:bold;
        font-size:40px;
    }

    .explorer-text {
        width:60%;
        margin:15px auto;
        font-size:20px;
    }

    .features-img {
        margin:50px 0;
        text-align:center;
    }

    .features-img img {
        width:60%;
    }

    @media screen and (max-width:1200px) {
        .about-text {
            text-align:center;
            font-size:20px;
            width:70%;
        }
        
        .mining {
            display:block;
            text-align:center;
        }

        .mining-content {
            margin:auto;
            margin-top:20px;
            width:70%;
            font-size:20px;
        }

        .contract-content {
            text-align:center;
            margin-top:50px;
        }

        .about-img img {
            width:70%;
            margin:0 auto;
        }

        .hero-img img {
            width:80%;
        }

        .about-img {
            text-align:center;
        }

        .mining-img img {
            width:70%;
        }

        .hero-text {
            font-size:20px;
        }

        .contract-img img {
            width:70%;
        }

        .contract-img {
            text-align:center;
        }

        .contract-title {
            font-size:30px;
        }

        .contract-content {
            width:70%;
        }
    }
</style>