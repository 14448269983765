import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/HomeView.vue';
import Menu from './views/MenuView.vue';
import Contract from './views/ContractView.vue';
import Mining from './views/MiningView.vue';
import About from './views/AboutView.vue';

const routes = [
  { path: '/:lang/', name: 'Home', component: Home, props: true },
  { path: '/:lang/menu', name: 'Menu', component: Menu, props: true },
  { path: '/:lang/contract', name: 'Contract', component: Contract, props: true },
  { path: '/:lang/mining', name: 'Mining', component: Mining, props: true },
  { path: '/:lang/about', name: 'About', component: About, props: true },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;