<template>
    <div class="menu">
        <NavBar />
        <div class="menu-content" v-if="lang == 'en'">
            <div class="menu-link-lg" @click="goTo('About')">About</div>
            <div class="menu-link-lg" @click="goTo('Mining')">Mining</div>
            <div class="menu-link-lg" @click="goTo('Contract')">Smart Contract</div>
            <a href="https://explorer-cxt.coexstar.com/#/" target="_blank"><div class="menu-link">Explorer</div></a>
            <a href="https://coexstar.com/CXTWhitepaper.pdf" target="_blank"><div class="menu-link">Whitepaper</div></a>
        </div>
        <div class="menu-content" v-if="lang == 'kr'">
            <div class="menu-link-lg" @click="goTo('About')">CXT 추가 정보</div>
            <div class="menu-link-lg" @click="goTo('Mining')">채굴</div>
            <div class="menu-link-lg" @click="goTo('Contract')">스마트 계약</div>
            <a href="https://explorer-cxt.coexstar.com/#/" target="_blank"><div class="menu-link">탐색기</div></a>
            <a href="https://coexstar.com/CXTWhitepaper.pdf" target="_blank"><div class="menu-link">백서</div></a>
        </div>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'

export default {
    components: {
        NavBar
    },

    data() {
        return { 
            lang: 'en'
        }
    },

    methods: {
        goTo(route) {
            this.$router.push({name: route, params: {lang:  localStorage.getItem('lang')}})
        },

        setLang() {
            this.lang = localStorage.getItem('lang')
        }
    },

    mounted() {
        this.setLang()
    }
}
</script>

<style scoped>
    .menu {
        background-image: url(../assets/menu/menu-bg.png);
        background-size:cover;
        background-repeat:no-repeat;
        background-position: center;
        min-height:100vh;
    }

    .menu-content {
        padding:30px;
    }

    .menu-link-lg {
        font-size:50px;
        color:#838AC0;
        font-weight:bold;
        margin:30px;
        cursor:pointer;
    }

    .menu-link {
        font-size:30px;
        color:#838AC0;
        margin:30px;
        cursor:pointer;
    }

    a { 
        text-decoration:none;
    }
</style>