<template>
  <div class="footer">
    <img src="../assets/Home/logo.png" alt="" class="footer-logo">
    <div class="en" v-if="lang == 'en'">
        <div class="footer-links">
            <div class="links-column">
                <div class="link">About</div>
                <div class="link">Mining</div>
                <div class="link">Smart Contract</div>
            </div>
            <div class="links-column">
                <div class="link">Explorer</div>
                <div class="link">News</div>
                <div class="link">Contact Us</div>
            </div>
        </div>
    </div>
    <div class="kr" v-if="lang == 'kr'">
        <div class="footer-links">
            <div class="links-column">
                <div class="link">cxt추가 정보</div>
                <div class="link">채굴</div>
                <div class="link">스마트 계약을</div>
            </div>
            <div class="links-column">
                <div class="link">탐색기</div>
                <div class="link">소식</div>
                <div class="link">연락주세요</div>
            </div>
        </div>
    </div>
    <div class="footer-media">
        <img src="../assets/Home/footer in.png" alt="" class="media-img">
        <img src="../assets/Home/footer email.png" alt="" class="media-img">
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            lang: 'en'
        }
    },

    methods: {
        setLang() {
            this.lang = localStorage.getItem('lang')
        }
    },

    mounted() {
        this.setLang()
    }
}
</script>

<style scoped>
    .footer {
        background-color:#00073a;
        padding:50px;
        justify-content:space-between;
        display:flex;
        flex-wrap:wrap;
    }

    img {
        object-fit:contain;
        margin:auto 0;
    }
    
    .footer-links {
        display:flex;
        flex-wrap:wrap;
    }

    .links-column {
        margin:20px 0;
        margin-right:40px;
    }

    .link {
        margin-bottom:20px;
        font-size:20px;
    }

    .footer-media {
        margin:auto 0;
    }

    .media-img {
        margin:10px;
    }
</style>