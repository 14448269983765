<template>
    <div class="about">
        <NavBar />
        <div class="about-content" v-if="lang == 'en'">
            <!-- <img src="../assets/about/about hero.png" alt="" class="about-img"> -->
            <div class="mining-title mx-auto" style="font-size:40px;color:orange;width:60%;margin:0 auto;font-weight:bold;">
                Our aim is not to be bigger or better than Bitcoin but to be the most accessible and easiest to use amongst all the cryptocurrencies in the world.
            </div>
            <section class="carousel" aria-label="Gallery">
                <ol class="carousel__viewport">
                    <li ref="carouselSlide1" id="carousel__slide1"
                        tabindex="0"
                        class="carousel__slide">
                        <div class="about-box">
                    <div class="about-text">
                        CXT doesn’t require expensive hardware and enormous computing power to be mined, presenting a 
                        profitable venture especially for home miners. CXT mining works the same way as mining Bitcoins 
                        where when more miners enter the market, the difficulty increases to ensure that the level is static. 
                        Miners are rewarded with CXT for verifying blocks of transactions to the blockchain network and as 
                        more miners compete for rewards, the process becomes more difficult. CXT Halving occurs every 
                        two years until all CXT coins are released and are in circulation.
                    </div>
                    <div class="about-text">
                        No one could have anticipated the meteoric rise in the value of Bitcoin five years ago. Crypto has 
                        proved its potential benefits to humanity are vast. We’ve created a new crypto currency for all, with 
                        greater accessibility, but only time will tell what the future holds for CXT.
                        We know it will be a long journey but we’ll be with you every step of the way.
                    </div>
                </div>
                    </li>
                    <li ref="carouselSlide2" 
                        tabindex="0"
                        class="carousel__slide">
                        <img src="../assets/about/slide2-en.png" alt="" class="carousel-img">
                    </li>
                </ol>
                <aside class="carousel__navigation">
                    <ol class="carousel__navigation-list">
                    <li class="carousel__navigation-item">
                        <a @click="scrollToSlide('carouselSlide1')"
                        class="carousel__navigation-button">Go to slide 1</a>
                    </li>
                    <li class="carousel__navigation-item">
                        <a @click="scrollToSlide('carouselSlide2')"
                        class="carousel__navigation-button">Go to slide 2</a>
                    </li>
                    </ol>
                </aside>
                </section>
        </div>
        <!-- KOREAN -->
        <div class="about-content" v-if="lang == 'kr'">
            <!-- <img src="../assets/about/about hero.png" alt="" class="about-img"> -->
            <div class="mining-title mx-auto" style="font-size:40px;color:orange;width:60%;margin:0 auto;font-weight:bold;">
                저희의 목표는 비트코인보다 더 크고 좋은것이 되는 것이 아니라 전세계 모든 가상화폐중 가장 접근성 있고 사용하기 쉬운 것이 되는 것입니다.
            </div>
            <section class="carousel" aria-label="Gallery">
                <ol class="carousel__viewport">
                    <li ref="carouselSlide1" id="carousel__slide1"
                        tabindex="0"
                        class="carousel__slide">
                        <div class="about-box">
                            <div class="about-text">
                                CXT는 채굴에 비싼 성능의 컴퓨터가 필요하지 않아 집에서 채굴하는 채굴자들에게 수익성을 제공합니다. CXT 채굴은 비트코인을 채굴하는 것과 같은 방식으로 작동하는데, 더 많은 채굴자들이 시장에 진입하면 그 수준을 맞추기 위해 난이도가 증가합니다. 채굴자들은 블록체인 네트워크에 대한 거래 블록을 확인하면 CXT로 보상을 받고, 더 많은 채굴자들이 보상을 받기 위해 경쟁할수록 그 과정은 더욱 어려워집니다. CXT 반감기는 모든 CXT 코인이 출시되어 유통될 때까지 2년마다 발생합니다.
                            </div>
                            <div class="about-text">
                                5년 전만 해도 비트코인의 가치가 급격하게 상승할 줄은 아무도 예상하지 못했을 겁니다. 암호화폐는 인류에게 주는 잠재적 이익이 크다는 것을 입증했습니다. 우리는 접근성을 높여 모두를 위한 새로운 암호화폐를 만들었지만, CXT의 미래가 어떻게 될지는 시간이 지나야 알 수 있을 것입니다.
                                우리는 그것이 긴 여정이 될 것이라는 것을 알고 있지만 우리는 모든 단계에서 당신과 함께 할 것입니다.
                            </div>
                        </div>
                    </li>
                    <li ref="carouselSlide2" 
                        tabindex="0"
                        class="carousel__slide">
                        <div class="about-box">
                            <div class="about-text" style="font-size:30px;">
                                일반 정보
                            </div>
                            <div class="text-row">
                                <div>
                                    <div class="about-text">
                                        <span class="label">약자:</span> CXT
                                    </div>
                                    <div class="about-text">
                                        <span class="label">총 공급량:</span> 84,000,000
                                    </div>
                                    <div class="about-text">
                                        <span class="label">반감기:</span> 약 2년마다
                                    </div>
                                    <div class="about-text">
                                        <span class="label">현재 예상 값:</span> 0.1117달러
                                    </div>
                                    <div class="about-text">
                                        <span class="label">개발된 국가:</span> 미국
                                    </div>
                                    <div class="about-text">
                                        <span class="label">출시:</span> 2020년 11월 8일
                                    </div>
                                </div>
                                <div style="margin-right:30px;">
                                    <div class="about-text">
                                        <span class="label">마지막 채굴 가능 연도:</span> 2088년
                                    </div>
                                    <div class="about-text">
                                        <span class="label">범주:</span> 암호화폐
                                    </div>
                                    <div class="about-text">
                                        <span class="label">플랫폼:</span> 0.1117달러
                                    </div>
                                    <div class="about-text">
                                        <span class="label">접근:</span> 전세계
                                    </div>
                                    <div class="about-text">
                                        <span class="label">웹사이트:</span> cxt.digital
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <img src="../assets/about/slide2-kr.png" alt="" class="carousel-img"> -->
                    </li>
                </ol>
                <aside class="carousel__navigation">
                    <ol class="carousel__navigation-list">
                    <li class="carousel__navigation-item">
                        <a @click="scrollToSlide('carouselSlide1')"
                        class="carousel__navigation-button">Go to slide 1</a>
                    </li>
                    <li class="carousel__navigation-item">
                        <a @click="scrollToSlide('carouselSlide2')"
                        class="carousel__navigation-button">Go to slide 2</a>
                    </li>
                    </ol>
                </aside>
                </section>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import FooterComponent from '../components/FooterComponent.vue'

export default {
    components: {
        NavBar,
        FooterComponent,
    },

    data() {
        return {
            lang: 'en'
        }
    },

    methods: {
        setLang() {
            this.lang = localStorage.getItem('lang')
        },

        scrollToSlide(refName) {
            // Use $refs to access the DOM element with the specified ref name
            const element = this.$refs[refName];
            // Check if the element exists
            if (element) {
            // Scroll to the element using smooth behavior
            element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    },

    mounted() {
        this.setLang()
    }
}
</script>

<style scoped>
    .about {
        background-image: url(../assets/about/about-bg.png);
        background-size:cover;
        background-repeat:no-repeat;
        background-position: center;
        min-height:100vh;
    }

    .about-content {
        padding:30px;
        text-align:center;
    }

    .about-img {
        object-fit:contain;
        width:60%;
    }

    .carousel-img {
        object-fit:contain;
        width:60%;
        margin:auto;
        margin-top:5%;
    }

    .about-box {
        width:60%;
        padding:30px;
        margin:auto;
        margin-top:10%;
        text-align:left;
        background-image: url(../assets/about/box.png);
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;
    }

    .about-text {
        margin-bottom:20px;
        font-size:15px;
    }

    .about-title {
        font-size:20px;
        font-weight:bold;
    }

    .text-row {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        padding:50px;
    }

    .label {
        font-weight:bold;
        color:#FF5D03;
    }

    @keyframes tonext {
        75% {
            left: 0;
        }
        95% {
            left: 100%;
        }
        98% {
            left: 100%;
        }
        99% {
            left: 0;
        }
        }

        @keyframes tostart {
        75% {
            left: 0;
        }
        95% {
            left: -300%;
        }
        98% {
            left: -300%;
        }
        99% {
            left: 0;
        }
        }

        @keyframes snap {
        96% {
            scroll-snap-align: center;
        }
        97% {
            scroll-snap-align: none;
        }
        99% {
            scroll-snap-align: none;
        }
        100% {
            scroll-snap-align: center;
        }
        }

        body {
        max-width: 37.5rem;
        margin: 0 auto;
        padding: 0 1.25rem;
        font-family: 'Lato', sans-serif;
        }

        * {
        box-sizing: border-box;
        scrollbar-color: transparent transparent; /* thumb and track color */
        scrollbar-width: 0px;
        }

        *::-webkit-scrollbar {
        width: 0;
        }

        *::-webkit-scrollbar-track {
        background: transparent;
        }

        *::-webkit-scrollbar-thumb {
        background: transparent;
        border: none;
        }

        * {
        -ms-overflow-style: none;
        }

        ol, li {
        list-style: none;
        margin: 0;
        padding: 0;
        }

        .carousel {
        position: relative;
        padding-top: 40%;
        filter: drop-shadow(0 0 10px #0003);
        perspective: 100px;
        }

        .carousel__viewport {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        overflow-x: scroll;
        counter-reset: item;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        }

        .carousel__slide {
        position: relative;
        flex: 0 0 100%;
        width: 100%;
        background-color: none;
        height:500px;
        }

        .carousel__slide:nth-child(even) {
        background-color: none;
        }

        .carousel__slide:before {
        /* content: counter(item); */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-40%,70px);
        color: #fff;
        font-size: 2em;
        }

        .carousel__snapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        scroll-snap-align: center;
        }

        @media (hover: hover) {
        .carousel__snapper {
            animation-name: tonext, snap;
            animation-timing-function: ease;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }

        .carousel__slide:last-child .carousel__snapper {
            animation-name: tostart, snap;
        }
        }

        @media (prefers-reduced-motion: reduce) {
        .carousel__snapper {
            animation-name: none;
        }
        }

        .carousel:hover .carousel__snapper,
        .carousel:focus-within .carousel__snapper {
        animation-name: none;
        }

        .carousel__navigation {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        }

        .carousel__navigation-list,
        .carousel__navigation-item {
        display: inline-block;
        }

        .carousel__navigation-button {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background-color: #333;
        background-clip: content-box;
        border: 0.25rem solid transparent;
        border-radius: 50%;
        font-size: 0;
        transition: transform 0.1s;
        }

        .carousel::before,
        .carousel::after,
        .carousel__prev,
        .carousel__next {
        position: absolute;
        top: 0;
        margin-top: 37.5%;
        width: 4rem;
        height: 4rem;
        transform: translateY(-50%);
        border-radius: 50%;
        font-size: 0;
        outline: 0;
        }

        .carousel::before,
        .carousel__prev {
        left: -1rem;
        }

        .carousel::after,
        .carousel__next {
        right: -1rem;
        }

        /* .carousel::before,
        .carousel::after {
        content: '';
        z-index: 1;
        background-color: #333;
        background-size: 1.5rem 1.5rem;
        background-repeat: no-repeat;
        background-position: center center;
        color: #fff;
        font-size: 2.5rem;
        line-height: 4rem;
        text-align: center;
        pointer-events: none;
        } */

        /* .carousel::before {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
        }

        .carousel::after {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
        } */
</style>