<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {

  },

  methods: {
      // getLocation: function(ip) {
      //   const options = {
      //       method: 'GET',
      //       url: '/loc/' + ip,
      //       };
      //       this.axios(options)
      //           .then((response) => { 

      //             switch(response.data.countryCode) {
      //               default:
      //                 window.location.href = "/#/" + response.data.countryCode.toLowerCase();
      //                 localStorage.setItem('country', response.data.countryCode.toLowerCase())
      //                 break;
      //             }
      //           })
      //           .catch((error) => {
      //             console.log(error)
                 
      //           });
      // },
      // getIp: function() {

      //    const options = {
      //       method: 'GET',
      //       url: '/json',
      //       };
      //       this.axios(options)
      //           .then((response) => { 

      //             this.getLocation(response.data.ip)
      //           })
      //           .catch((error) => {
      //             console.log(error)
                 
      //           });
      //   }

      setLang() {
        if(localStorage.getItem('lang') == undefined) {
          this.$router.push({name: 'Home', params: {lang: 'en'}})
          localStorage.setItem("lang", "en")
          console.log('.')
        } else if(this.$route.params.lang != localStorage.getItem("lang")) {
          console.log(this.$route.params.lang + localStorage.getItem("lang"))
          this.$router.push({name: 'Home', params: {lang: localStorage.getItem("lang")}})
        }
      }
   },
 

    mounted(){
      this.setLang()
    },
}
</script>

<style>
  @font-face {
    font-family: Montserrat;
    src: url(./assets/fonts/Montserrat-Regular.otf);
  }

  body {
    margin:0px;
  }

  * {
    color:white;
    font-family:Montserrat;
  }
</style>
