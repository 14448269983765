<template>
    <div class="contract">
        <NavBar />
        <div class="contract-content" v-if="lang == 'en'">
            <div class="contract-img">
                <img src="../assets/Home/asset3.png" alt="">
            </div>
            <div class="hero-img">
                <!-- <img src="../assets/smart contract/sc hero.png" alt=""> -->
                <div class="mining-title" style="font-size:60px;color:orange;font-weight:bold;">IMPLEMENT YOUR VISION</div>
            </div>
            <div class="hero-title">Create smart contract with ease.</div>
            <div class="hero-text">CXT is an Asset Agreement (Smart Contract) implementation specifically on the CXT Blockchain (aka CXTchain)</div>
            <div class="hero-text">representing a digital asset for the purpose of creating value.</div>
            <div class="hero-text">CXT applies the Proof of Stake (PoS) protocol to generate and operate CXT Assets.</div>
        </div>
        <div class="contract-content" v-if="lang == 'kr'">
            <div class="contract-img">
                <img src="../assets/Home/asset3.png" alt="">
            </div>
            <div class="hero-img">
                <!-- <img src="../kr-assets/smart contract/sc hero.png" alt=""> -->
                <div class="mining-title" style="font-size:60px;color:orange;font-weight:bold;">당신의 꿈을 실현하세요</div>
            </div>
            <div class="hero-title">스마트 계약을 쉽게 만드세요</div>
            <div class="hero-text">CXT는 가치 창출을 목적으로 디지털 자산을 대표하는 CXT 블록체인(일명 CXTchain)에 특화된 자산 계약(스마트 계약)의 적용입니다. </div>
            <div class="hero-text">CXT는 CXT 자산을 생성하고 운영하기 위해 지분 증명(PoS) 프로토콜을 적용합니다.</div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import FooterComponent from '../components/FooterComponent.vue'
export default {
    components: {
        NavBar,
        FooterComponent
    },

    data() {
        return {
            lang: 'en'
        }
    },

    methods: {
        setLang() {
            this.lang = localStorage.getItem('lang')
        }
    },

    mounted() {
        this.setLang()
    }
}
</script>

<style scoped>
    .contract {
        background-image: url('../assets/smart contract/sc-bg.png');
        background-size:cover;
        background-repeat:no-repeat;
        background-position: center;
        min-height:100vh;
    }

    .contract-content {
        text-align:center;
        padding:30px;
    }

    .contract-img img {
        width:80%;
        margin:0 auto;
    } 

    .hero-title {
        font-size:30px;
        font-weight:bold;
        margin:15px 0;
    }

    .hero-text {
        margin:15px 0;
    }

    @media screen and (max-width:800px) {
        .hero-img img {
            width:80%;
        }
    }
</style>