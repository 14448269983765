<template>
    <div class="navbar">
        <div class="logo" @click.prevent="goToHome">
            <img src="../assets/Home/logo.png" alt="">
        </div>
        <div class="right">
            <div class="nav-select">
                <select v-model="lang" @change.prevent="changeLang()">
                    <option value="en">English</option>
                    <option value="kr">한국</option>
                </select>
            </div>
            <div class="menu" @click.prevent="clickMenu" style="cursor:pointer;">
                <p>{{ lang == 'en' ? 'Menu' : '메뉴' }}</p> 
                <img src="../assets/Home/menu.png" alt="" class="menu-img">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            lang: 'en'
        }
    },
    methods: {
        goToHome() {
            this.$router.push({name: 'Home', params: {lang: localStorage.getItem('lang')}})
        },

        clickMenu() {
            console.log(this.$route.name)
            if(this.$route.name == 'menu') {
                this.$router.go(-1)
            } else {
                console.log('/')
                this.$router.push({name: 'Menu', params: {lang:localStorage.getItem('lang')}})
            }
        },

        changeLang() {
            if(localStorage.getItem("lang") != this.lang) {
                localStorage.setItem("lang", this.lang)
                this.$router.push({name: 'Home', params: {lang: localStorage.getItem("lang")}})
                window.location.reload();
            }
        },

        setLang() {
            this.lang = localStorage.getItem("lang")
        }
    },
    
    mounted() {
        this.setLang();
    }
}
</script>

<style scoped>
    .navbar {
        padding:50px;
        display:flex;
        justify-content:space-between;
    }

    .menu {
        display:flex;
    }

    .menu p {
        margin:auto 0;
        margin-right:5px;
    }

    .right {
        display:flex;
    }

    .nav-select {
        margin:auto 0;
    }

    select {
        color:white;
        margin:auto 0;
        margin-right:20px;
        padding:5px;
        background:none;
        border:1px white solid;
        border-radius:5px;
    }

    option {
        color:black;
    }

    .logo img {
        object-fit:contain;
        width:80px;
    }
</style>